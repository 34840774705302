import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AboutUsItem from '../common/aboutUsItem';
import Info from '../common/info';
import { WHITE } from '../../constants/colors';
import {
  TABLET, media, CONTAINER_WIDTH, CONTAINER_LAPTOP_WIDTH, LAPTOP,
} from '../../constants/screens';

const AboutUsDPB = ({
  image: { file: { localFile: { childImageSharp: { sizes } } } },
  title,
  subTitle,
  description,
}) => (
  <AboutUsDPBContainer>
    <MainContent>
      <AboutUsItem
        image={sizes}
        maxImageWidth="530px"
        imgAltAttr="SlashData"
        reversed
        css={`
          justify-content: center;

          & > div:nth-child(even) {
            margin-right: 30px;
          }

          & > div:nth-child(odd) {
            margin-left: 30px;
          }

          ${media.tablet`
            & > div:nth-child(n) {
              margin-right: 0;
              margin-left: 0;
            }
          `}
        `}
      >
        <Info
          subTitleFontSize="40px"
          title={title}
          subTitle={subTitle}
          text={description}
          pageName="developer-program-benchmarking"
        />
      </AboutUsItem>
    </MainContent>
  </AboutUsDPBContainer>
);

AboutUsDPB.propTypes = {
  image: PropTypes.shape().isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

const AboutUsDPBContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${WHITE};
  padding: 300px 5% 240px 5%;
  
  @media screen and (max-width: ${LAPTOP}) {
    padding: 220px 5% 150px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    padding: 200px 5% 80px 5%;
  }
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width:  ${CONTAINER_WIDTH};
  width: 100%;
  align-items: center;
  
  ${media.laptop`
    max-width: ${CONTAINER_LAPTOP_WIDTH};
  `};
`;


export default AboutUsDPB;
