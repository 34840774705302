import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import throttle from 'lodash.throttle';
import { BLACK } from '../../constants/colors';
import { media } from '../../constants/screens';

const Collapse = ({ children, duration, height }) => {
  const contentRef = useRef(null);
  const [collapsed, setCollapsed] = useState(true);
  const [enabled, setEnabled] = useState(true);

  useEffect(() => {
    const handleResize = throttle(() => {
      setEnabled(
        contentRef.current
          ? contentRef.current.offsetHeight > height
          : true,
      );
    }, 100);

    handleResize();

    window.addEventListener(
      'resize',
      handleResize,
    );

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Content>
      {enabled ? (
        <>
          <Container
            duration={duration}
            height={collapsed ? height : 'auto'}
            collapsed={collapsed}
          >
            {children(contentRef)}
          </Container>
          <Button collapsed={collapsed} onClick={() => setCollapsed(false)}>Show more</Button>
        </>
      ) : children(contentRef)}

    </Content>
  );
};

Collapse.propTypes = {
  children: PropTypes.func,
  height: PropTypes.number,
  duration: PropTypes.number,
};

Collapse.defaultProps = {
  children: null,
  height: 800,
  duration: 300,
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Button = styled.button`
  font-size: 15px;
  font-weight: 500;
  border: none;
  background: none;
  -webkit-appearance: none;
  color: ${BLACK};
  text-transform: uppercase;
  margin-top: 10px;
  outline: none;
  
  ${media.laptop`
    font-size: 13px;
  `}
  
  ${({ collapsed }) => !collapsed && css`
     display: none;
  `};
`;

const Container = styled(({ collapsed, ...rest }) => <AnimateHeight {...rest} />)`
  position: relative;
  
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 80%;
    opacity: 1;
    background-image: linear-gradient(to top, rgba(255,255,255,1) 50%, rgba(255,255,255,0));
    transition: all ${({ duration }) => duration / 1000}s ease-in;
  }
  
  ${({ collapsed }) => !collapsed && css`
    &:after {
      opacity: 0;
      top: 100%;
    }
  `}
`;

export default Collapse;
