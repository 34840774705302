import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Container } from '../../typography';
import Item from '../reportItem/item';
import Collapse from '../collapse';

// eslint-disable-next-line
const Reports = ({ items }) => (
  <Collapse duration={500}>
    {
      ref => (
        <Container ref={ref}>
          <Items>
            {items.map(({ node }) => <Item key={node.id} {...node} />)}
          </Items>
        </Container>
      )
    }
  </Collapse>
);

Reports.defaultProps = {
  items: [],
};

Reports.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
};

const Items = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export default Reports;
