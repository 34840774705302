import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'ramda';
import { graphql } from 'gatsby';
import withCustomLayout from '../enhancers/withCustomLayout';
import AboutUsDPB from '../components/dpbPage/aboutUsDpb';
import { DARK_SCHEME, LIGHT_GRAY_1, WHITE } from '../constants/colors';
import withStickyHeader from '../enhancers/withStickyHeader';
import withChildRoutes from '../enhancers/withChildRoutes';
import BrochureRequest from '../components/brochureRequestPage';
import ImageSection from '../components/common/imageSection';
import Questions from '../components/common/questions';
import { media } from '../constants/screens';
import Section from '../components/common/section';
import Reports from '../components/common/reports';
import ContactUsWhite from '../components/common/contactUsWhite';

const DPBPage = (props) => {
  const {
    data: {
      page: { introduction, sections = [] },
      developerProgramBenchmarking: { edges: dpbs = [] },
    },
  } = props;

  const questions = sections.filter(({ meta }) => meta && meta.includes('questions'));
  const imageSection = sections.filter(({ meta }) => meta && meta.includes('image'));
  const contactUsSection = sections.filter(({ meta }) => meta && meta.includes('contact'));

  return (
    <div>
      <AboutUsDPB {...introduction} />
      {imageSection && (
        <ImageSection
          bg={LIGHT_GRAY_1}
          {...imageSection[0]}
          fullWidth
        />
      )}
      {questions && (
        <Questions
          {...questions[0]}
          bg={WHITE}
          css={`padding-bottom: 100px; ${media.tablet`padding-bottom: 0;`}`}
        />
      )}
      {sections[2] && (
        <Section
          {...sections[2]}
          bg={WHITE}
          css={`padding-bottom: 100px; ${media.tablet`padding-bottom: 0;`}`}
        />
      )}
      {dpbs.length && (
        <Reports items={dpbs} />
      )}
      {contactUsSection && (
        <ContactUsWhite {...contactUsSection[0]} />
      )}
    </div>
  );
};

DPBPage.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default compose(
  withChildRoutes({
    defaultRoute: '/developer-program-benchmarking',
    childRoutes: [
      { path: '/developer-program-benchmarking/:slug', component: BrochureRequest },
    ],
  }),
  withStickyHeader(),
  withCustomLayout({ scheme: DARK_SCHEME }),
)(DPBPage);

/* eslint-disable no-tabs */
// eslint-disable-next-line
export const query = graphql`
  query DPBPageQuery {
    page(url: { regex: "/developer-program-benchmarking/" }) {
      introduction {
        title
        subTitle
        description
        image {
          file {
            localFile {
              childImageSharp {
                sizes(maxWidth: 700) {
                  ...GatsbyImageSharpSizes_noBase64
                }
              }
            }
          }
        }
      }
      sections {
        title
        description
        meta
        content {
          id: _id
          description {
            md
          }
        }
        button {
          url
          text
        }
        image {
          alt
          file {
            localFile {
              publicURL
              childImageSharp {
                sizes(maxWidth: 670) {
                  ...GatsbyImageSharpSizes_noBase64
                }
                fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
    developerProgramBenchmarking: allDpb(sort: { fields: [ publishedAt ], order: [ DESC ] }) {
      edges {
        node {
          id
          title
          slug
          subtitle
          meta {
            description
          }
          brochure {
            url
          }
          publishedAt(formatString: "MMMM YYYY")
          cover {
            localFile {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid_noBase64
                }
                sizes(maxWidth: 500) {
                  ...GatsbyImageSharpSizes_noBase64
                }
              }
            }
          }
          extra {
            content {
              html
            }
          }
          brochure {
            url
          }
        }
      }
    }
    clients: allClient {
      edges {
        node {
          id
          name
          logo{
            localFile {
              childImageSharp {
                sizes(maxWidth: 500) {
                  ...GatsbyImageSharpSizes_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`;
