import React from 'react';
import PropTypes from 'prop-types';
import { pathOr } from 'ramda';
import GatsbyLink from 'gatsby-link';
import styled from 'styled-components';
import { Container, H2 as BaseH2 } from '../typography';
import GradientButton from '../buttons/gradientButton';
import { WHITE } from '../../constants/colors';
import { media } from '../../constants/screens';

const ContactUsWhite = ({
  title, image, button: { url, text }, ...rest
}) => (
  <Container
    bg={`
      ${WHITE}
      url(${pathOr(null, ['file', 'localFile', 'publicURL'], image)})
      center 50px/contain
      no-repeat
    `}
    {...rest}
  >
    <H2>{title}</H2>
    {(url && text) ? (
      <GradientButton
        tag={GatsbyLink}
        text={text}
        to={url}
      />
    ) : null}
  </Container>
);

const H2 = styled(BaseH2)`
  max-width: 400px;
  
  ${media.mobile`
    max-width: 250px;
  `}
`;

ContactUsWhite.propTypes = {
  title: PropTypes.string,
  button: PropTypes.shape({}),
  image: PropTypes.shape({}),
};

ContactUsWhite.defaultProps = {
  title: '',
  button: { url: '', text: '' },
  image: {},
};

export default ContactUsWhite;
